export default class Events {

	constructor(channel) {
		this.channel = channel
	}
	
	connected() {
		$('body').attr('data-chat-connected', '')
	}
	
	disconnected() {
		$('body').removeAttr('data-chat-connected')
	}
	
	received(data) {
		var bottom = this.chat.bottom()
		
		this.channel.broadcasts.add(data)
	}
	
	speak() {
		this.channel.elements.message_new_content.val('')
	}
	
	resize() {
		//console.log('..')
		
		window.chat && window.chat.elements.messages.find('.message').get(0) && window.chat.snap({animate: false})
	}
}