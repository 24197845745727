$(document).on('click', 'body[data-options-visible]', function(event){
	$('*[data-options]').trigger('options:hide')
})

$(document).on('click', '[data-option]', function(event){
	event.preventDefault()
	
	var options = $('[data-options="' + $(this).attr('data-option') + '"]')

	options.attr('data-options-active', '')
	$('body').attr('data-options-visible', '')
})

$(document).on('click', '*[data-options] nav ul li a', function(event){
	$(this).parents('*[data-options]').trigger('options:hide')
})

$(document).on('options:show', '[data-options]', function(event){
	$('.overlay').addClass('active')
	
	var parent = $(this).parents('*').first()
	
	$(this).attr('data-options-active', '')
	$('body').attr('data-options-visible', '')
	parent.attr('data-options-visible', '')
	
	if($(this).parents('.part-content').outerHeight() > (parent.position().top + parent.outerHeight() + $(this).find('nav').outerHeight())){
		$(this).attr('data-anchor', 'bottom')
	} else {
		$(this).attr('data-anchor', 'top')
	}	
	
/*
	var top = ($(this).offset().top - $('.os-viewport').offset().top)
	
	console.log(top)
		
	if(((top + $(this).outerHeight()) > $('.os-viewport').height()) && top > 100){
		$(this).attr('data-anchor', 'top')
	} else {
		$(this).attr('data-anchor', 'bottom')
	}
*/
})

$(document).on('options:hide', '*[data-options]', function(event){
	$('*[data-options]').removeAttr('data-options-active')
	$('*[data-options-visible]').removeAttr('data-options-visible')
});