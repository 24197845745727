Handlebars.registerHelper("breaklines", function (text) {
  text = Handlebars.Utils.escapeExpression(text);
  text = text.replace(/(\r\n|\n|\r)/gm, "<br>");
  return new Handlebars.SafeString(text);
});

// {{#if user.badge}}<span class="message-user-badge"><i class="fal fa-badge-check"></i> {{user.badge}}</span>{{/if}}

var template = Handlebars.compile(`
	<div class="message" data-role="{{user.role}}" data-kind="{{kind}}" data-token="{{token}}" data-user="{{user.token}}">
		{{#if attachment}}
			<div class="message-attachment" data-kind="{{attachment.kind}}" data-token="{{attachment.token}}">
				{{#if attachment.url}}
					<a href="{{attachment.url.url}}" target="_blank">
						<div class="row">
							<div class="col-auto"><figure><img src="{{attachment.url.image}}" /></figure></div>
							<div class="col">
								<span>{{attachment.url.title}}</span>
								<small>{{attachment.url.description}}</small>
							</div>
						</div>
					</a>
				{{/if}}
				{{#if attachment.upload}}
					<a href="{{attachment.upload.link}}" target="_blank"><i class="fal fa-{{attachment.upload.icon}}"/></a>
				{{/if}}
			</div>
		{{/if}}
		<div class="message-user">{{user.name_first}} {{user.name_last}}</div>
		<div class="message-content"><p>{{breaklines content}}</p></div>
		<div class="message-time">{{time}}</div>
		{{#if url}}
			<a href="{{url}}"></a>
		{{/if}}
	</div>`);

export class Message {
  constructor(object) {
    this.object = object;
  }

  own() {
    return window.chat.user_token == this.object.user.token;
  }

  element() {
    this.object.time = moment(this.object.date).format("HH:mm");
    this.object.datestamp = moment(this.object.date).format("YYYYMMDD");
    var element;

    if (this.object.kind == "cleared") {
      this.object.content = "Dit bericht is verwijderd door de Tutor.";
    }

    element = $(template(this.object));

    if (!window.chat.message_token && this.object.kind == "do") {
      element.attr("data-kind-alt", "");
    }

    if (
      this.object.kind != "cleared" &&
      ((this.own() && element.attr("data-kind-alt") == undefined) ||
        window.chat.role == "tutor")
    ) {
      element.attr("data-has-options", "");
      element.append('<div class="message-options"></div>');
    }

    if (this.own()) element.attr("data-own", "");

    return $(element.get(0).outerHTML);
  }

  options() {}
}

export default class Messages {
  constructor(chat) {
    this.chat = chat;
  }

  atTheEnd = false;
  prevAppendDate = false;
  prevPrependDate = false;

  add(object, { append = true, first = false, highlight = false } = {}) {
    var message = new Message(object);
    var element = message.element();
    var datestamp = moment(object.date).format("YYYYMMDD");

    if (highlight) {
      element.attr("data-highlight", "");
    }

    if (!this.prevAppendDate) {
      this.prevAppendDate = datestamp;
    }

    if (append) {
      if (this.chat.bottom() || message.own()) {
        setTimeout(function () {
          window.chat.snap();
        }, 50);
      }

      this.chat.elements.messages.append(element);

      if (this.prevAppendDate != datestamp) {
        this.prevAppendDate = datestamp;
        element.before(
          `<div class="messages-date" data-date="${datestamp}">${moment(
            this.prevAppendDate
          ).format("dddd D MMM YYYY")}</div>`
        );
      }
    } else {
      if (!this.prevPrependDate) {
        this.prevPrependDate = datestamp;
      }

      this.chat.elements.messages.prepend(element);

      if (object.last == true) {
        element.before(
          `<div class="messages-date" data-date="${datestamp}">${moment(
            object.date
          ).format("dddd LL")}</div>`
        );
      }

      if (this.prevPrependDate != datestamp) {
        element.after(
          `<div class="messages-date" data-date="${datestamp}">${moment(
            this.prevPrependDate
          ).format("dddd LL")}</div>`
        );
        this.prevPrependDate = datestamp;
      }
    }

    setTimeout(() => {
      element.addClass("a");
    }, 1500);
  }

  options(token) {
    var message_element = this.find(token);

    message_element.append($('[data-options="message"]'));

    $('[data-options="message"]').trigger("options:show");

    var element_attachment_destroy = $(
      '[data-options="message"] li[data-name="attachment-destroy"]'
    );

    if (message_element.find(".message-attachment").get(0)) {
      element_attachment_destroy.show();
    } else {
      element_attachment_destroy.hide();
    }

    if (
      message_element.attr("data-user") == window.chat.user_token &&
      message_element.attr("data-kind-alt") == undefined
    ) {
      $('[data-options="message"] ul li[data-name="message-edit"]').show();
    } else {
      $('[data-options="message"] ul li[data-name="message-edit"]').hide();
    }

    $('[data-options="message"] ul li a').each(function () {
      $(this).attr(
        "href",
        $(this)
          .attr("data-token-url")
          .replace(
            "-message_token-",
            $(this).parents(".message").attr("data-token")
          )
          .replace(
            "-attachment_token-",
            $(this)
              .parents(".message")
              .find(".message-attachment")
              .attr("data-token")
          )
      );
    });

    //window.chat.overlayScrollbars.scroll({ el: message_element.get(0), margin: true }, 100)
  }

  find(token) {
    return this.chat.elements.messages.find(
      '.message[data-token="' + token + '"]'
    );
  }

  remove(token) {
    this.find(token).remove();
  }

  update(object) {
    var element = this.find(object.token);

    this.chat.elements.chat.append($('[data-options="message"]'));

    var message = new Message(object);
    var new_element = message.element();

    if (message.own() && object.kind == "cleared") {
      $('[data-options="message"]').trigger("options:hide");
    }

    element.replaceWith(new_element);

    if (window.chat.bottom()) {
      window.chat.snap();
    }
  }

  updated(object) {
    $.getJSON(
      paths.challenge_message
        .replace("-challenge_token-", this.chat.challenge_token)
        .replace("-message_token-", object.token),
      function (data) {
        window.chat.messages.remove(data.token);

        window.chat.messages.add(data, { highlight: true });

        if (window.chat.bottom()) {
          window.chat.snap();
        }
      }
    );
  }

  get(options = { token: false, append: false }) {
    if (this.atTheEnd) return;

    var t = options.token || this.token || "";

    if (!options.append) window.chat.elements.messages.attr("data-loading", "");

    $.getJSON(
      paths.challenge_message
        .replace("-challenge_token-", this.chat.challenge_token)
        .replace(
          "/-message_token-",
          this.chat.message_token ? "/" + this.chat.message_token : ""
        ) +
        ".json?l=1&t=" +
        t +
        "&a=" +
        options.append,
      function (data) {
        window.chat.elements.messages.removeAttr("data-loading");

        var first_element = window.chat.elements.messages.find(".message");

        window.chat.messages.atTheEnd = !options.append && data.length == 0;

        data.forEach((d, i) => {
          window.chat.messages.add(d, { append: options.append });

          if (!options.append && i == data.length - 1) {
            window.chat.messages.token = d.token;

            if (window.chat.firstSnap) {
              setTimeout(function () {
                window.chat.snap();
              }, 250);
            } else if (first_element.get(0)) {
              window.chat.elements.chat_container.scrollTop(
                first_element.position().top
              );
            }
          }
        });
      }
    );
  }
}
