// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("autosize");
require("jquery");
require("channels");
require("chat");
require("webics");

var FastClick = require("fastclick");

$(function () {
  FastClick.attach(document.body);
});

import "../gems/client_side_validations.js";

import "slick-carousel/slick/slick.min.js";

moment.locale("nl");

import PerfectScrollbar from "perfect-scrollbar";

$(document).on("turbolinks:load", function () {
  autosize($("textarea"));

  setTimeout(function () {
    autosize.update($("textarea"));
  }, 1);

  // if($('html').attr('data-bp') == 'ios') {
  // 	$('.part-content').each(function(i){
  // 		setTimeout ( () => {
  // 			new PerfectScrollbar($(this).get(0), {
  // 				wheelSpeed: 1,
  // 				suppressScrollX: true
  // 			})
  // 		}, 5000)
  // 	})
  // }

  // $('.part-middle .part-content').each(function(){
  // 	disableBodyScroll($('.part-middle .part-content').get(0))
  // })
});

window.binds = [];

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
