$(function () {
  if (window.navigator.standalone) {
    $("html").attr("data-app", "");
  }
});

if (window.ReactNativeWebView) {
  window.ReactNativeWebView.appStateChange = function (currentState, newState) {
    if (currentState == "background" && newState == "active") {
      try {
        if (
          window.chat.messages &&
          (message = $(".messages .message:last-child")).get(0)
        ) {
          window.chat.messages.get({
            token: message.attr("data-token"),
            append: true,
          });
        }
      } catch (e) {}
    }
  };

  window.ReactNativeWebView.registerDevice = function (data) {
    $.post("/devices", {
      device: { platform: data.platform, token: data.token },
    });
  };

  window.ReactNativeWebView.unregisterDevice = function (data) {
    $.get({
      url: "/devices/" + data.token,
      type: "DELETE",
    });
  };

  window.ReactNativeWebView.back = function (data) {
    if ($(".bar-back").get(0)) {
      Turbolinks.visit($(".bar-back").attr("href"));
    } else {
      window.ReactNativeWebView.postMessage(JSON.stringify({ name: "close" }));
    }
  };

  window.ReactNativeWebView.networkState = function (state) {
    if (window.ReactNativeWebView.networkStateTimeout) {
      clearTimeout(window.ReactNativeWebView.networkStateTimeout);
    }

    window.ReactNativeWebView.networkStateTimeout = setTimeout(
      function () {
        $(".connection").remove();

        if (state == "none") {
          $("body").append(
            '<div class="connection"><div class="connection-container"><div class="connection-description"><strong>Let op:</strong> Er is geen actieve internet verbinding</div></div></div>'
          );
        }
      },
      100,
      state
    );
  };

  window.ReactNativeWebView.pushNotification = function (data) {
    if (data.data.url == window.parent.location || data.os == "android") {
      return;
    }

    if (!$(".notification").get(0)) {
      $("body").append(
        '<div class="notification"><div class="notification-container"><a href="" class="cover"></a><div class="notification-title"></div><div class="notification-body"></div></div></div>'
      );
    }

    if (window.notification_timeout) {
      clearTimeout(window.notification_timeout);
    }

    setTimeout(function () {
      $(".notification").addClass("notification-active");
    }, 100);

    $(".notification a").attr("href", data.data.url);
    $(".notification .notification-title").text(data.notification.title);
    $(".notification .notification-body").text(data.notification.body);

    /*
		if( data.alert.title.indexOf('DO:') > 0 ) {
			$('.notification').attr('data-color', 'blue')
		} else {
			$('.notification').attr('data-color', 'red')
		}
*/

    window.notification_timeout = setTimeout(function () {
      $(".notification").removeClass("notification-active");
    }, 4000);
  };

  $(function () {
    $("html").attr("data-app-native", "");

    if ($("body[data-us]").get(0)) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ name: "push-register" })
      );
    } else {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ name: "push-unregister" })
      );
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ name: "badge", value: 0 })
      );
    }
  });

  $(document).on("turbolinks:load", function () {
    if ($('meta[name="app-badge"]').get(0)) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          name: "badge",
          value: $('meta[name="app-badge"]').attr("content"),
        })
      );
    }
  });

  $(document).on("click", "a", function (event) {
    var element = $(event.target);
    var href = $(this).attr("href");

    if (href.indexOf("webics-app-kind") > 0) {
      event.preventDefault();

      if (href.indexOf("webics-app-kind=file") > 0) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ name: "file", value: href })
        );
      } else {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ name: "browser", value: href })
        );
      }
    }
  });

  $(document).on("click", 'input[type="file"]', function (event) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ name: "permission-camera" })
    );
  });
}
