window.ClientSideValidations.callbacks.element.fail = function(element, message, callback){
	callback()
	
	$(element).parents('.form-group').find('.field-with-errors-message').text(message)
}

window.ClientSideValidations.callbacks.element.pass = function(element, callback) {
	callback()
	
	$(element).parents('.form-group').find('.field-with-errors-message').remove()
}

/*
$(function(){
	$(document).on('keyup', 'form *[data-validate]', function(event){
		$(this).data('changed', true).isValid($(this).closest('form').get(0).ClientSideValidations.settings.validators)
		$(this).focus()
	})
})
*/