$(document).on('click', '.accordion-title', function(event){
	if($(event.target).prop('tagName') == 'A' || $(event.target).parents('a').get(0))
		return true
	
	var accordionParent = $(this).closest('li')
	
	if(!accordionParent.get(0)){
		var accordionParent = $('.accordion li:first-child, .bar.accordion-title')
	}
	
	var active = accordionParent.attr('data-accordion-active')
	
	$('.accordion li, .bar.accordion-title').removeAttr('data-accordion-active')
	
	if(active == undefined){
		accordionParent.attr('data-accordion-active', '')
	}
})