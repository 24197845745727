$(document).on('click change', '*[data-tab-name]', function(event){
	event.preventDefault()
	
	var tabs = $('*[data-tabs="' + $(this).attr('data-tabs-name') + '"]').trigger('tabs:change')
	
	tabs.attr('data-tabs-tab', $(this).attr('data-tab-name'))

	var tab = $('*[data-tab="' + $(this).attr('data-tab-name') + '"]')
	
	$('*[data-tabs-value="' + $(this).attr('data-tabs-name') + '"]').val($(this).attr('data-tab-name')).trigger('tabs:value')
	

	$('*[data-tabs-name="' + tabs.attr('data-tabs') + '"][data-tab-active]').removeAttr('data-tab-active').trigger('tab:hide')
	
	tab.attr('data-tab-active', '').trigger('tab:hide')
	$(this).attr('data-tab-active', '').trigger('tab:hide')
	
	if(event.type == 'click') {
		//location.hash = '#tab-' + $(this).attr('data-tab-name')
	}
	
	$(this).removeAttr('data-count')
})

$(document).on('click', '*[data-tabs-close]', function(event){
	event.preventDefault()
	
	$(this).parents('*[data-tabs]').removeAttr('data-tabs-tab').trigger('tabs:close')
})

$(window).bind('hashchange', function(){
	var hash = location.hash.replace('#', '')
	
	if(hash.indexOf('tab-') == 0){
		$('*[data-tab-name="' + hash.replace('tab-', '') + '"]').trigger('change')
	}
})

$(document).on('turbolinks:load', function(){
	if($(window).width() < 768){
		$('*[data-tabs][data-sm-tabs-tab]').each(function(){
			$('*[data-tab-name="' + $(this).attr('data-sm-tabs-tab') + '"]').trigger('change')
		})
	}
})

window.tabs = {
	incrementCount: function(tab){
		var element = $(`*[data-tab-name="${tab}"]`)
		
		if(element.attr('data-tab-active') == undefined) {
			if(element.attr('data-count') == undefined){
				element.attr('data-count', 1)
			} else {
				element.attr('data-count', parseInt(element.attr('data-count')) + 1)
			}
		} else {
			element.removeAttr('data-count')
		}
	}
}