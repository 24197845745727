import consumer from "../channels/consumer"

export default class Subscription {
	
	constructor(options, callbacks){		
		this.connection = consumer.subscriptions.create(options, callbacks)
	}
	
	unsubscribe(){
		this.connection.unsubscribe()
	}
}