window.binds = {}

export default class Binds {
	
	constructor(chat){
		this.chat = chat

		this.scroll()
		//this.keyup()
		this.attachmentChange()
		this.activeStorage()
		this.form()
		this.textarea()
		this.closeNewMessage()
		this.closeNewAttachment()
		this.messageOptions()
		this.messageTabs()
		this.messageButton()
	}
	
	scroll() {
		this.chat.elements.chat_container.bind('scroll', function(){
			if($(this).prop('scrollTop') == 0){
				window.chat.messages.get()
			}
		})
	}
	
	messageTabs() {
		this.chat.elements.message_new.bind('tabs:close tabs:change', function(){
			window.chat.snap()
		})
	}
	
	activeStorage() {
		this.chat.elements.message_new_attachment_file.bind('direct-upload:initialize direct-upload:start direct-upload:progress', function(event){
			if(event.originalEvent.detail.progress){
				$('.message-new-attachment-progress span').css('width', event.originalEvent.detail.progress + '%')
			}
		})
		
		this.chat.elements.message_new_form.bind('direct-uploads:start direct-uploads:end', function(event){
			if(event.type == 'direct-uploads:start') {
				window.chat.busy()
			}
		})
	}
	
	messageButton() {
		this.chat.elements.message_new_button.bind('click', function(){
			window.chat.elements.message_new_content.focus()
		})
	}
	
	messageOptions() {
		if(window.binds.message_options){
			$(document).off('click', '.message[data-has-options] .message-options', window.binds.message_options)
			
			window.binds.message_options = null
		}
		
		window.binds.message_options = function(event){
			event.preventDefault()
			window.chat.messages.options($(this).parent('.message').attr('data-token'))
		}

		$(document).on('click', '.message[data-has-options] .message-options', window.binds.message_options)
	}

	form() {
		this.chat.elements.message_new_form.bind('submit', function(event){
			if(window.chat.elements.message_new_attachment_file.get(0).files.length == 0){
				window.chat.elements.message_new_attachment_file.prop('disabled', true)
			}
		})
		
		this.chat.elements.message_new_form.bind('ajax:beforeSend ajax:success ajax:error', function(event){

			if(event.type == 'ajax:beforeSend'){
				window.chat.busy()
			}
			
			if(event.type == 'ajax:success' || event.type == 'ajax:error') {
				window.chat.resetMessage()

				if(event.type == 'ajax:success'){
					var kinds = [event.originalEvent.detail[0].kind]
					switch(kinds[0]){
						case 'do':
						case 'conclusion':
							if(kinds[0] == 'do' || window.chat.challenge_token){
								if(kinds[0] == 'conclusion')
									kinds.push('reflect')
							
								kinds.forEach(function(kind){
									$(`*[data-tab-name="${kind}"], *[data-tab="${kind}"]`).remove()
								})
	
								window.chat.elements.message_new_close.trigger('click')
							}
						break;
						case 'reflect':
							window.chat.elements.message_new_close.trigger('click')
					}
				} else {
					alert('Kan bericht niet plaatsen, probeer het nogmaals.')
				}
			}
		})
	}
	
	textarea() {
		this.chat.elements.message_new_kind.bind('tabs:value', function(event){
			window.chat.elements.message_new_content.focus()
		})
		
		this.chat.elements.message_new_content.bind('input update', function(event){
			if($(this).val() == '') {
				window.chat.elements.message_new.attr('data-contentless', '')
				window.chat.elements.message_new_button.attr('disabled', '')
			} else {
				window.chat.elements.message_new.removeAttr('data-contentless')
				window.chat.elements.message_new_button.removeAttr('disabled')
			}
		})
		
		this.chat.elements.message_new_content.bind('click', function(event){
			$(this).trigger('focus')
		})
		
		this.chat.elements.message_new_content.bind('focus blur', function(event){
			$(this).trigger('update')
			
			if(event.type == 'focus') {
				$('body').attr('data-chat-input', '')
			} else {
				//$('body').removeAttr('data-chat-input')
			}
		})
	}
	
	closeNewMessage() {
		this.chat.elements.message_new_close.bind('click', function(event){
			event.preventDefault()
			
			$('body').removeAttr('data-chat-input')
			window.chat.elements.message_new.removeAttr('data-tabs-tab')
			window.chat.elements.message_new_kinds.find('*[data-tab-name]').removeAttr('data-active')
			
			window.chat.snap()
			
			window.chat.resetMessage()
		})
	}
	
	keyup() {
		this.chat.elements.message_new_content.bind('keydown', function(event){
			if(event.which == 13) {
				event.preventDefault()
				
				window.chat.elements.message_new_button.trigger('click')
			}
		})
	}
	
	closeNewAttachment(){
		this.chat.elements.message_new_attachment_remove.bind('click', function(event){
			event.preventDefault()
	
			window.chat.attachment.reset()
		});
	}
	
	attachmentChange() {
		this.chat.elements.message_new_attachment_file.bind('change', function(event){
			//$(this).parents('*[data-options]').removeAttr('data-options-active')
			
			window.chat.attachment.add($(this))
		})
	}
	
}