export default class Broadcasts {
	
	constructor(channel){
		this.channel = channel
		this.chat = channel.chat
	}
	
	add(data) {

		var body = {challenge_token: this.channel.subscriptionOptions.challenge}
		
		if(this.channel.subscriptionOptions.channel == 'ChatChannel') {
			body.message_token = this.channel.subscriptionOptions.message
		}

		if(!this.chat.getBody(body).get(0)) return false
		
		switch(data.broadcast.type){
			case 'message':
				this.chat.messages.add(data.broadcast.object)
			break;
			case 'message-updated':
				this.chat.messages.update(data.broadcast.object)
			break;
			case 'update-view':
				this.chat.updateView(data.broadcast.object)
			break;
			case 'increment-count':
				var element = $(`.list *[data-token="${data.broadcast.object.token}"]`)
				var number = parseInt(element.attr('data-number')) + 1
				
				element.attr('data-number', number).find('.list-number').text(number).attr('class', 'list-number background-' + data.broadcast.object.color)
				
			break;
		}
	}
}