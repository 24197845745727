export default class Attachment {
	constructor(chat) {
		this.chat = chat
	}
	
	add(input){
		if(input.get(0).files && input.get(0).files[0]){
			
			if((input.get(0).files[0].size / 1024 / 1024) > 10){
				this.reset()

				alert('Bijlage mag niet groter zijn dan 10mb')

				return
			}
			
			this.chat.elements.message_new_attachment_filename.text(input.get(0).files[0].name)
			
			this.chat.elements.message_new.attr('data-attachment', '')
		}
	}
	
	reset(){
		this.chat.elements.message_new.removeAttr('data-attachment')
		this.chat.elements.message_new_attachment_file.val('').removeAttr('disabled')
		$('input[type="hidden"][name="' + this.chat.elements.message_new_attachment_file.attr('name') + '"]').remove()
	}
}