import Messages from "./messages"
import Events from "./events"
import Attachment from "./attachment"
import Binds from "./binds"
import Broadcasts from "./broadcasts"
import Subscription from "./subscription"

export class Chat {
	channels = {}

	initialize() {
		this.challenge_token = $('body').attr('data-ch')
		this.message_token = $('body').attr('data-ms')
		this.user_token = $('body').attr('data-us')
		this.role = $('body').attr('data-role')
		
		this.setElements()
		
		this.firstSnap = true
		
		if(this.elements.chat.get(0)) {
			this.load()
		} else {
			this.disconnect()
		}
	}
	
	
	load(){
		this.binds = new Binds(this)
		this.messages = new Messages(this)
		this.attachment = new Attachment(this)
		
		var challenge_channel = this.createChannel({channel: "ChallengeChannel", challenge: this.challenge_token})
		
		var optionsForChatChannel = {channel: "ChatChannel", challenge: this.challenge_token}
		
		if(this.message_token) {
			optionsForChatChannel.message = this.message_token
		}
		
		var chat_channel = this.createChannel(optionsForChatChannel)		
		
		this.disconnect({challenge_channel: challenge_channel, chat_channel: chat_channel})
		
		this.messages.get()
	}
	
	disconnect(channels = false) {
		Object.keys(this.channels).forEach(function(identifier){
			var channel = window.chat.channels[identifier]
			
			
			if( channels && (channel.identifier == channels.challenge_channel.identifier || channel.identifier == channels.chat_channel.identifier)){
			} else {
				window.chat.channels[identifier].disconnect()				
				delete window.chat.channels[identifier]
			}
		})
	}
	
	createChannel(subscription){
		var channel;
		
		if(subscription.channel == 'ChallengeChannel') {
			channel = new ChallengeChannel(this, subscription)
		} else {
			channel = new ChatChannel(this, subscription)
		}
		
		if(this.channels[channel.identifier]){
			
		} else {
			channel.connect()
			
			this.channels[channel.identifier] = channel
		}
		
		return channel;
	}
	
	snap(options = {animate: true}) {
		window.chat.elements.chat_container.animate({ scrollTop: this.elements.chat_container.prop('scrollHeight') + 300}, (this.firstSnap || !options.animate ? 0 : 250))

		this.firstSnap = false
	}
	
	updateView(object) {
		$(`*[data-xhr="${object.name}"]`).trigger('xhr:request')
		
		window.tabs.incrementCount(object.name)
	}

	resetMessage() {
		this.elements.message_new_content.val('')
		this.attachment.reset()
		this.elements.message_new_content.trigger('update')
		this.elements.message_new_attachment_file.removeAttr('disabled')

		this.done()
	}

	bottom() {
		return ( window.chat.elements.chat_container.scrollTop() + this.elements.chat_container.innerHeight() >= this.elements.chat_container.get(0).scrollHeight )
	}

	busy() {
		$('body').attr('data-chat-busy', '')
	}

	done() {
		$('body').removeAttr('data-chat-busy')
	}

	reset() {
		window.chat.message_new_attachment_file.prop('disabled', false)
		window.chat.elements.messages.html('')
	}
	
	getBody(tokens = {}){
		var body = `body[data-ch="${tokens.challenge_token}"]`

		if(tokens.message_token){
			body += `[data-ms="${tokens.message_token}"]`
		}
		
		return $(body)
	}

	setElements(){
		var body = this.getBody({challenge_token: this.challenge_token, message_token: this.message_token})

		this.elements = {
			body: body,
			chat: body.find('.chat'),
			chat_container: body.find('.chat-container'),
			messages_container: body.find('.messages-container'),
			messages: body.find('.messages'),
			message_new: body.find('.message-new'),
			message_new_form: body.find('.new_message'),
			message_new_button: body.find('.message-new-button'),
			message_new_content: body.find('#message_content'),
			message_new_kind: body.find('#message_kind'),
			message_new_kinds: body.find('.message-new-kinds'),
			message_new_attachment: body.find('.message-new-attachment'),
			message_new_attachment_remove: body.find('.message-new-attachment-remove'),
			message_new_attachment_filename: body.find('.message-new-attachment-filename'),
			message_new_attachment_file: body.find('#message_attachment_attributes_file'),
			message_new_close: body.find('.message-new-close')
		}
	}

}

export class Channel {

	constructor(chat, subscriptionOptions){
		this.chat = chat
		
		this.subscriptionOptions = subscriptionOptions
	
		this.identifier = subscriptionOptions.channel + "-" +  subscriptionOptions.challenge + "-" + subscriptionOptions.message
		
		
		this.broadcasts = new Broadcasts(this)
	}	

	connect(){
		this.initialize()
		
		this.subscription = new Subscription(
			this.subscriptionOptions,
			{connected: this.connected.bind(this), received: this.received.bind(this), disconnected: this.disconnected.bind(this)}
		)
	}

	disconnect(){
		this.subscription.unsubscribe()
	}
}

export class ChallengeChannel extends Channel {

	initialize(){
		
	}

	connected(){
	}

	received(data){
		this.broadcasts.add(data)
	}

	disconnected(){
		
	}
}

export class ChatChannel extends Channel {

	initialize() {
		this.events = new Events(this)
	}

	connected(){
		$('body').attr('data-chat-connected', '')
	}

	received(data){
		var bottom = window.chat.bottom()
		
		this.broadcasts.add(data)
	}

	disconnected(){
		$('body').removeAttr('data-chat-connected')
	}
	

/*
	speak() {
		this.subscription.perform("speak", {content: this.elements.message_new_content.val(), kind: this.elements.message_new_kind.val(), challenge: this.challenge})
		
		this.events.speak()
	}
*/

}