$(document).on('turbolinks:load', function(event){
	if($('body[data-role]').get(0)){
		$(`body *[data-role]:not([data-role="${$(this).attr('data-role')}"])`).remove()
		
/*
		$(`body *[data-role]:not([data-role="${$(this).attr('data-role')}"])`).each(function(){
			console.log($(this).attr('data-role'))
			
			if( $(this).attr('data-role') != $('body').attr('data-role') ){
				$(this).remove()
			}
		})
*/
	}
});