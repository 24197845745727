import './app';
import './tabs';
import './tooltip';
import './options';
import './xhr';
import './role';
import './errors';
import './form';
import './flash';
import './accordion';
import './page';
import './slider';
import './list';

import Modal from "../webics/modal"

$(function(){
	window.modal = new Modal()
})

$(document).on('click', '[data-modal]', function(event){
	event.preventDefault()

	window.modal.open($(this).attr('href'))
})

