export default class Modal {
	
	constructor(){
		this.stuff()		
	}
	
	element() {
		if(!$('#modal').get(0))
			$('main .part-middle').append('<div id="modal"><div class="modal"><iframe scrolling="yes" allowtransparency="true" frameborder="0" src="about:blank" name="modal"></iframe></div></div>')

		return $('#modal')
	}
	
	open(url, options = {}){
		this.element().find('iframe').attr('src', url).bind('load', function(){
			$('#modal').attr('data-loaded', '1')
		})

		$('body').attr('data-modal', '1')
		
		if(options.top){
			$('body').attr('data-modal-top', '1')
		}
	}

	close(){
		$('body').removeAttr('data-modal').removeAttr('data-modal-top')
		this.element().remove()
	}

	is_parent(){
		return (window.location.href == window.parent.location.href)
	}
	
	stuff(){
		if($('body').attr('data-modal-path') != undefined){
			this.open($('body').attr('data-modal-path'), {top: true})
		}
		
		if(!this.is_parent()){
			$('html').attr('data-layout', 'modal')
			
			$(document).on('click', 'html[data-layout="modal"] body', function(event){
				if(!$(event.target).closest('.card').get(0) && !$(event.target).closest('.welcome').get(0)){
					window.parent.modal.close()	
				}
			})		
	
			$(document).on('click', '[data-layout="modal"] .card-header', function(event){
				window.parent.modal.close()	
			})
		}

	}
}