$(document).on("click", "table.list tbody tr", function (event) {
  var target = $(event.target);

  if (!target.closest("a").get(0)) {
    event.preventDefault();

    var a;

    if (!(a = target.closest("td").find("a").first()).get(0)) {
      a = $(this).find("a").last();
    }

    if (a.attr("data-method") == undefined) {
      a.trigger("click");
    }
  }
});
