$(function(){
	$(".slides").slick({
		infinite: false,
		dots: true,
		prevArrow: $('button.slides-prev'),
		nextArrow: $('button.slides-next')
	})
	
	$('.slides').bind('beforeChange', function(event, slick, currentSlide, nextSlide){
		if($('.slides .slide').length == (nextSlide+1)){
			$('.welcome-content').attr('data-last', '')
		} else {
			$('.welcome-content').removeAttr('data-last')
		}
	})

	$('.slides-close').bind('click', function(event){
		event.preventDefault()
		Turbolinks.visit($(this).attr('href'))	
	})

})