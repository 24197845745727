import { length } from 'stringz'

$(document).on('input character-count', '[maxlength]', function(){
	$(this).closest('.form-group').attr('data-character-count', $(this).attr('maxlength') - length($(this).val()))
})

$(document).on('turbolinks:load', function(){
	$('[maxlength]').trigger('character-count')
})

$(document).on('change', '.file-image input', function(){
	$(this).closest('form').submit()
})