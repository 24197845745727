$(document).on('turbolinks:load', function(){
	$(document).on('xhr:request', '*[data-xhr]', function(){
		$(this).attr('data-loading', '')
		
		$.get({
			url: $(this).attr('data-xhr-url'),
			context: $(this),
			success: function(data){
				$(this).html(data).removeAttr('data-loading')
			}
		})
	})
		
	$('*[data-xhr]').each(function(){
		$(this).trigger('xhr:request')
	})
})

$(document).on('ajax:success ajax:before ajax:error', '*[data-remote]', function(event, data){
	$('*[data-options]').trigger('options:hide')
	
	var target = $(event.target)
	var success = target.attr('data-remote-success')
	if(success){
		if(success.indexOf('remove') == 0){
			$(success.split('remove%')[1]).remove()
		}
	}
})